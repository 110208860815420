<template>
  <b-card no-body @click="loadTask()" style="cursor: pointer">
    <b-card-header>
      <b-card-title>
        #{{task.id}} - {{ task.title }}
      </b-card-title>
      <div v-if="task.deadline!==null">
        Deadline: {{new Date(task.deadline).toLocaleDateString()}} <span v-if="!task.late" style="color:red">(Past Due!)</span>
      </div>
      <div v-else>
        No deadline specified
      </div>
    </b-card-header>
  </b-card>
</template>

<script>
export default {
  name: "TaskCard",
  props: [
      "task"
  ],
  methods: {
    loadTask() {
      this.$router.push('/task/'+this.task.id);
    },
  }
}
</script>

<style scoped>

</style>