<template>
  <div>
    <div v-if="!search_open">
    <b-button-group>
      <b-button variant="success" :to="'/edit-task'">New Task</b-button>
      <b-button variant="primary" @click="showFilter()">Search Tasks</b-button>
    </b-button-group>
    <b-card>
      <b-card-header>
        <b-card-title>Tasks assigned to me </b-card-title>
      </b-card-header>
      <b-card-body>
        <p v-if="my_tasks.assigned.length===0">
          You don't have any assigned tasks. Good job.
        </p>
        <TaskCard v-else v-for="task in my_tasks.assigned" :key="task.id" :task="task"> </TaskCard>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>Tasks I've created</b-card-title>

      </b-card-header>
      <b-card-body>
        <p v-if="my_tasks.authored.length===0">
          You haven't created any tasks
        </p>
        <TaskCard v-else v-for="task in my_tasks.authored" :key="task.id" :task="task"> </TaskCard>
      </b-card-body>
    </b-card>
    </div>
    <div v-else>
      <b-form  @submit.stop.prevent>
        <b-row>
          <b-col cols="6">
            <b-form-select v-model="filter_author_select" :options="filter_author_options">
              <template #first>
                <b-form-select-option :value="null">-- Select Author --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
          <b-col cols="6">
            <b-form-select v-model="filter_status_select" :options="['pending', 'active', 'complete']">
              <template #first>
                <b-form-select-option :value="null">-- Select Status --</b-form-select-option>
              </template>
            </b-form-select>
          </b-col>
        </b-row>

        <b-form-input v-model="filter_text" placeholder="Contains text..."></b-form-input>
        <div class="text-right">
          <b-button squared variant="primary" v-on:click="search">Search</b-button>
          <b-button squared variant="danger" v-on:click="hideFilter">Close Search</b-button>
        </div>
      </b-form>
      <TaskCard v-for="task in filter_tasks" :key="task.id" :task="task"></TaskCard>
    </div>
  </div>
</template>

<script>
import TaskCard from "@/components/TaskCard";
import http from "../http-common";

export default {
  name: "TaskHome",
  props: [
      "tags",
      "users",
      "my_tasks",
      "loggedIn",
  ],
  components: {
      TaskCard,
  },
  data: function () {
    return {
      search_open: false,
      filter_author_select: null,
      filter_status_select: null,
      filter_text: "",
      filter_tasks: [],
      current_page: 0,
      tasks_per_page: 10,
    };
  },
  methods: {
    search() {
      let args = "&";
      if (this.filter_status_select === "pending") {
        args = args.concat('filter_status=1&');
      } else if (this.filter_status_select === "active") {
        args = args.concat('filter_status=2&');
      } else if (this.filter_status_select === "complete") {
        args = args.concat('filter_status=3&');
      }
      if (this.filter_author_select !== null) {
        args = args.concat(`filter_author=${encodeURIComponent(this.filter_author_select)}&`);
      }
      if (this.filter_text !== "") {
        args = args.concat(`filter_text=${encodeURIComponent(this.filter_text)}&`);
      }
      http.get('/task/'+
          `?offset=${this.current_page*this.tasks_per_page}`+
          `&limit=${this.tasks_per_page}`+ args)
      .then((resp) => {
        console.log(resp.data);
        this.filter_tasks = resp.data.tasks;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })

    },
    showFilter() {
      this.filter_author_select = null;
      this.filter_status_select = null;
      this.filter_text = "";
      this.filter_tasks = [];
      this.search_open = true;
    },
    hideFilter() {
      this.search_open = false;
    },
  },
  computed: {
    filter_author_options() {
      let lst = [];
      this.users.forEach((usr) => {
        lst.push(usr.username);
      });
      return lst;
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.$emit('sync-my-tasks');
    this.$emit('sync-users');
  },
}
</script>

<style scoped>

</style>